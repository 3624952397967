import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from './components/header';
import MQTT from './components/mqtt';
import Home from './routes/home';
import AOSLineChart from './routes/chart';
import Settings from './routes/settings';
import Device from './routes/device';
import NotFound from './routes/404';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import 'typeface-roboto';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#0044ff',
    }
  },
  aos_colours: {
    red: '#ec1739',
    blue: '#4190d4',
    orange: '#f69219',
    aqua: '#00b49d',
  },
});

class App extends React.Component {
  render() {
    return (
        <Router>
          <ThemeProvider theme={theme}>
            <div>
              <Header>
                <MQTT>
                  <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/chart/" component={AOSLineChart} />
                    <Route path="/device/" component={Device} />
                    <Route path="/settings/" component={Settings} />
                    <Route component={NotFound} />
                  </Switch>
                </MQTT>
              </Header>
            </div>
          </ThemeProvider>
        </Router>
    );
  }
}

export default App;
