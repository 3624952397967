import createStore from "redux-zero";
 
const initialState = {
                        connectDeviceIP: '',
                        mqttConnected: false,
                        mqttClient: null,
                        O2Timestamps: [],
                        oxygenData: [],
                        tempTimestamps: [],
                        temperatureData:[],
                        connectedProbeReader: null,
                    };
const store = createStore(initialState);
 
export default store;