import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { connect } from "redux-zero/react";
import Container from "@material-ui/core/Container";
import actions from "../../store/actions";
import { Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import { get, set } from "idb-keyval";
import { css } from "@emotion/core";
import { ClipLoader } from "react-spinners";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import NewProbeReaderDialog from '../../components/newProbeForm';

const mapToProps = ({ mqttConnected, mqttClient, oxygenData }) => ({
  mqttConnected,
  mqttClient,
  oxygenData
});

const styles = theme => ({
	root: {
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	paper: {
		padding: theme.spacing(3, 2),
	},
	button: {
		margin: theme.spacing(1),
	},
	connectButton: {
		backgroundColor: theme.aos_colours.blue,
		color: '#fff',

	},
	prevDevices: {
		marginBottom: theme.spacing(2),
		fontWeight: 'bold',
	},
	newDevice: {
		marginBottom: theme.spacing(2),
		fontWeight: 'bold',
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 175,
	},
	card: {
		maxWidth: 250,
	},
	details: {
		//display: 'flex',
		//flexDirection: 'column',
	},
	content: {
		flex: '1 0 auto',
	},
	cover: {
		height: 150,
  },
  fab: {
    margin: theme.spacing(1),
    float: 'right'
  },
});

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connecting: false,
      probeReaders: null,
      connectingText: "",
      probeReaderName: "",
      modalOpen: false
    };
  }

  handleModalOpen = () => {
    this.setState({modalOpen: true});
  }

  handleModalClose = value => {
    this.setState({modalOpen: false});
  };

  componentDidMount() {
    get("readers").then(val => {
      this.setState({ probeReaders: val });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.mqttConnected) {
        this.setState({ connecting: false });
      }
      get("readers").then(val => {
        this.setState({ probeReaders: val });
      });
    }
  }

  saveConnectionToDatabase(name, ip, port) {
    var alreadyInList = false;
    get("readers").then(val => {
      if (val === undefined) {
        val = [];
      }
      val.forEach(element => {
        if (
          element.name === name &&
          element.url === ip &&
          element.port === port
        ) {
          alreadyInList = true;
        }
      });
      if (!alreadyInList) {
        val.push({ name: name, url: ip, port: port });
      }
      set("readers", val);
    });
  }

  removeConnectionFromDatabase(name, ip, port) {
    get("readers").then(val => {
      var newArray = [];
      if (val === undefined) {
        newArray = [];
      }

      val.forEach(element => {
        if (
          element.name !== name ||
          element.url !== ip ||
          element.port !== port
        ) {
          newArray.push(element);
        }
      });
      set("readers", newArray);
      this.setState({ probeReaders: newArray });
    });
  }

  startConnection = (name, url, port) => {
    this.setState({ connecting: true, probeReaderName: name });
    this.props.connectToDeviceIP(url);
    this.saveConnectionToDatabase(name, url, port);
  };

  endConnection = () => {
    this.setState({ connecting: false, probeReaderName: '' });
    this.props.connectToDeviceIP('');
  }

  render() {
    const { classes } = this.props;
    return (
      <Container maxWidth="xl" className={classes.root}>
        {this.props.mqttConnected ? (
          <React.Fragment>
            <Typography>You are connected to {this.state.probeReaderName}</Typography>
            <Typography component="h5" variant="h5">
              IP:{" "}
              {this.props.mqttClient.options.hostname
                .split(".")[0]
                .replace(/-/g, ".")}
            </Typography>
            <Typography>
              Currently streaming data from your probe reader, select the live stream tab to view.
            </Typography>
            <Button variant="contained" color="secondary" onClick={() => this.endConnection()}>
              Disconnect
            </Button>
          </React.Fragment>
        ) : this.state.connecting ? (
          <React.Fragment>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <ClipLoader
                  css={override}
                  sizeUnit={"px"}
                  size={150}
                  color={"#123abc"}
                  loading={this.state.connecting}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>{this.state.connectingText}</Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {this.state.probeReaders ? (
              this.state.probeReaders.length > 0 ? (
                <React.Fragment>
                  <Typography variant="h5">Previously Connected Devices</Typography>
                  <Grid
                    container
                    justify="center"
                    className={classes.root}
                    spacing={2}
                  >

                    {this.state.probeReaders.map(objects => (
                      <Grid
                        item
                        key={
                          objects.name + "_" + objects.url + "_" + objects.port
                        }
                        md={4}
                        xs={12}
                      >
                        <Card className={classes.card}>
                          <div className={classes.details}>
                            <CardMedia
                                className={classes.cover}
                                image="probe-reader.jpg"
                                title="AOS 02TA-2200"
                              />
                            <CardContent className={classes.content}>
                              <Typography component="h5" variant="h5">
                                {objects.name}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                color="textSecondary"
                              >
                                IP: {objects.url}
                              </Typography>
                            </CardContent>
                            <CardActions>
                              <Button
                                size="small"
                                color="secondary"
                                onClick={() =>
                                  this.startConnection(
                                    objects.name,
                                    objects.url,
                                    objects.port
                                  )
                                }
                              >
                                Connect
                              </Button>
                              <IconButton
                                className={classes.button}
                                onClick={() =>
                                  this.removeConnectionFromDatabase(
                                    objects.name,
                                    objects.url,
                                    objects.port
                                  )
                                }
                                aria-label="delete"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </CardActions>
                          </div>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                  <br />
                  <br />
                </React.Fragment>
                )
                : (
                  <React.Fragment>
                    <Typography>You have no saved probe readers. Use the + button in the bottom right hand corner to add one.</Typography>
                  </React.Fragment>
                )
              
            ) : (
              <React.Fragment>
                <Typography>You have no saved probe readers. Use the + button in the bottom right hand corner to add one.</Typography>
              </React.Fragment>
            )}
            <NewProbeReaderDialog open={this.state.modalOpen} onClose={this.handleModalClose} startConnection={this.startConnection}/>
            <Fab color="secondary" aria-label="add" className={classes.fab} onClick={this.handleModalOpen}>
              <AddIcon />
            </Fab>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
  connect(
    mapToProps,
    actions
  )(Home)
);
