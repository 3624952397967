const actions = store => ({
    connectMQTT: state => ({ mqttConnected: true }),
    connectToDeviceIP: (state, address) => ({connectDeviceIP: address}),
    disconnectMQTT: state => ({ mqttConnected: false }),
    mqttClientConnect: (state, client) => ({mqttClient: client}),
    mqttClientDisconnect: (state) => ({mqttClient: null}),
    connectProbeReader: (state, reader) => ({connectedProbeReader: reader}),
    disconnectProbeReader: (state) => ({connectedProbeReader: null}),
    updateOxygenGraphData: (state, newO2Time, newOxygen) => ({O2Timestamps: newO2Time,
                                                               oxygenData: newOxygen,
                                                               }),
    updateTemperatureGraphData: (state, newTempTime, newTemp) => ({tempTimestamps: newTempTime,
                                                                   temperatureData: newTemp
                                                                  }),
    clearGraphData: (state) => ({O2Timestamps: [], oxygenData: [], tempTimestamps: [], temperatureData: []})
  });
   
  export default actions;