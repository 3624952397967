import React from "react";
import mqtt from "mqtt";
import { connect } from "redux-zero/react";
import actions from "../../store/actions";
import moment from "moment";
import worker from "../../app.worker.js";
import WebWorker from "../../WebWorker.js";

const mapToProps = ({
  mqttConnected,
  mqttClient,
  connectDeviceIP,
  O2Timestamps,
  oxygenData,
  tempTimestamps,
  temperatureData
}) => ({
  mqttConnected,
  mqttClient,
  connectDeviceIP,
  O2Timestamps,
  oxygenData,
  tempTimestamps,
  temperatureData
});

class MQTT extends React.Component {
  handleNewMessage = payload => {
    const newMsg = JSON.parse(payload.toString());

    var convertedTimestamps = [];
    newMsg["time-points"].forEach(time_element => {
      const time = moment(time_element).format("YYYY-MM-DD HH:mm:ss.SS");
      convertedTimestamps.push(time);
    });

    var messageDict = {
      oxygenTime: this.props.O2Timestamps,
      oxygenData: this.props.oxygenData,
      tempTime: this.props.tempTimestamps,
      tempData: this.props.temperatureData,
      signal: newMsg["signal"],
      span: newMsg["seconds-span"],
      epochTimestamps: newMsg["time-points"],
      timestamps: convertedTimestamps,
      values: newMsg["values"]
    };

    return messageDict;
  };

  startConnection = () => {
    var dashedURL = this.props.connectDeviceIP.replace(/[.]/g, "-");

    var client = mqtt.connect(
      `wss://${dashedURL}.ip.dns.australianoxytrolsystems.com:9004`
    );

    if (client) {
      client.on("connect", () => {
        this.props.mqttClientConnect(client);
        while (!this.props.mqttClient) {}
        var receivedTemp = false;
        var receivedOxygen = false;
        var historyReceived = false;
        client.subscribe("probe-reader/+/configuration"); // Config topic
        client.subscribe("probe-reader/+/response/history"); // History topic

        client.on("message", (topic, payload) => {
          //Handle the config message
          if (RegExp("probe-reader/[0-9A-z]+/configuration").test(topic)) {
            const newMsg = JSON.parse(payload.toString());
            this.props.connectProbeReader(newMsg);
            this.setState({ connectingText: "Received device config" });
            var clientID = newMsg["id"];
            if(!historyReceived)
            {
                client.publish(`probe-reader/${clientID}/request/history`, ""); //Publish to the history topic
                historyReceived = true;
            }
          }

          //Handle the history message
          else if (
            RegExp("probe-reader/[0-9A-z]+/response/history").test(topic)
          ) {
            const newMsg = JSON.parse(payload.toString());
            this.setState({ connectingText: "Received history message" });

            var convertedTimestamps = [];
            newMsg["time-points"].forEach(time_element => {
              const time = moment(time_element).format(
                "YYYY-MM-DD HH:mm:ss.SS"
              );
              convertedTimestamps.push(time);
            });
            this.setState({ connectingText: "Finished converting timestamps" });

            if (newMsg["signal"] === "oxygen") {
              this.setState({ connectingText: "Received oxygen data" });
              this.props.updateOxygenGraphData(
                convertedTimestamps,
                newMsg["values"]
              );
              receivedOxygen = true;
            } else if (newMsg["signal"] === "temperature") {
              this.setState({ connectingText: "Received temperature data" });
              this.props.updateTemperatureGraphData(
                convertedTimestamps,
                newMsg["values"]
              );
              receivedTemp = true;
            }

            // Wait until all of the historical data has been received
            if (receivedOxygen && receivedTemp) {
              this.setState({ connectingText: "Received all data" });
              this.setState({ connecting: false });
              this.props.connectMQTT();
              client.unsubscribe("probe-reader/+/request/history");
              client.subscribe("probe-reader/+/data/#");
            }
          } else if (RegExp("probe-reader/[0-9A-z]+/data/.+").test(topic)) {
            var messageDict = this.handleNewMessage(payload);
            this.worker.postMessage(messageDict);
          }
        });
      });

      client.on("error", err => {
        console.log("Could not connect to your probe reader.");
        console.log(err);
      });

      client.on("offline", err => {
        console.log("Device is offline, connection failed");
        console.log(err);
      });
    }
  };

  endConnection = () => {
    if (this.props.mqttClient) {
      this.props.mqttClient.end();
      this.props.disconnectMQTT();
      this.props.mqttClientDisconnect();
      this.props.mqttClient.unsubscribe("probe-reader/#");
    }
    this.worker.terminate();
  };

  unsubscribeFromDatastream() {
    if (this.props.mqttClient) {
      this.props.mqttClient.unsubscribe("probe-reader/#");
    }
    this.worker.terminate();
  };

  componentDidMount() {
    this.worker = new WebWorker(worker);

    this.worker.addEventListener("message", event => {
      if (event.data.dataType === "oxygen") {
        this.props.updateOxygenGraphData(
          event.data.O2Timestamps,
          event.data.oxygen
        );
      } else if (event.data.dataType === "temperature") {
        this.props.updateTemperatureGraphData(
          event.data.tempTimestamps,
          event.data.temperature
        );
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.connectDeviceIP === "" && this.props.connectDeviceIP !== "") {
      this.startConnection();
    }
    else if (prevProps.connectDeviceIP !== "" && this.props.connectDeviceIP === "") {
      this.endConnection();
    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default connect(
  mapToProps,
  actions
)(MQTT);
