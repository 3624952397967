import React from "react";
import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  root: {}
});

class Settings extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Container maxWidth="xl" className={classes.root}>
        <Typography variant="h4">Settings</Typography>
        <Typography variant="subtitle1">Your device settings</Typography>
      </Container>
    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Settings);
