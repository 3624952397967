import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "redux-zero/react";
import actions from "../../store/actions";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import Divider from "@material-ui/core/Divider";
import { cyan } from "@material-ui/core/colors";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  title: {
    marginLeft: theme.spacing()
  },
  appBar: {
    marginLeft: drawerWidth,
    color: cyan,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  appToolBar: {
    justifyContent: 'space-between',
  },
  imageHeader:{
		//backgroundColor: "blue",
		float: "right",
		[theme.breakpoints.up('md')]: {
			display: 'none',
		  },
	},
  toolbar: {
    textAlign: "center",
    ...theme.mixins.toolbar
  },
  image: {
    marginTop: theme.spacing()
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
});

const mapToProps = ({ mqttConnected }) => ({ mqttConnected });

class Header extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      drawerOpen: false
    };
  }

  handleDrawerChange = () => {
    this.setState(prevState => ({
      drawerOpen: !prevState.drawerOpen
    }));
  };

  render() {
    const { classes } = this.props;

    const drawer = (
      <div>
        <div className={classes.toolbar}>
          <Link to="/">
            <img
              className={classes.image}
              src="logo-aos.png"
              alt="aos-logo"
              height="40"
            ></img>
          </Link>
          <br />
          <Typography variant="caption">
            V{process.env.REACT_APP_VERSION}
          </Typography>
        </div>
        <Divider />
        <List>
          <ListItem button key="home" component={Link} to="/">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          {this.props.mqttConnected ? (
            <React.Fragment>
              <ListItem button key="chart" component={Link} to="/chart">
                <ListItemIcon>
                  <ShowChartIcon />
                </ListItemIcon>
                <ListItemText primary="Live Data" />
              </ListItem>
              <ListItem button key="device" component={Link} to="/device">
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText primary="Device Configuration" />
              </ListItem>
              {/*
							<ListItem button key='settings' component={Link} to="/settings">
								<ListItemIcon><SettingsIcon /></ListItemIcon>
								<ListItemText primary='Settings' />
							</ListItem>
							*/}
            </React.Fragment>
          ) : null}
        </List>
        <Divider />
      </div>
    );

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.appToolBar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={this.handleDrawerChange}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Link className={classes.imageHeader} to='/'><img className={classes.image} src="logo-aos.png" alt="aos-logo" height="40"></img></Link>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="Mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden mdUp implementation="css">
            <Drawer
              variant="temporary"
              anchor="left"
              open={this.state.drawerOpen}
              onClose={this.handleDrawerChange}
              classes={{
                paper: classes.drawerPaper
              }}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.props.children}
        </main>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
  connect(
    mapToProps,
    actions
  )(Header)
);
