import React from "react";
import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import { connect } from "redux-zero/react";
import actions from "../../store/actions";
import { Redirect } from "react-router-dom";

const styles = theme => ({
  root: {}
});

const mapToProps = ({ connectedProbeReader }) => ({ connectedProbeReader });

class Device extends React.Component {
  render() {
    const { classes } = this.props;
    return this.props.connectedProbeReader ? (
      <Container maxWidth="xl" className={classes.root}>
        <Typography variant="h4">Configuration</Typography>
        <br />
        <Typography variant="subtitle1">Your current hardware configuration:</Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Typography>ID</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>{this.props.connectedProbeReader.id}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Gas Configuration</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>{this.props.connectedProbeReader.gasConfiguration}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Logging State</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>{this.props.connectedProbeReader.loggingState}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Storage State</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>{this.props.connectedProbeReader.storageState}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Thermocouple Type</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>{this.props.connectedProbeReader.thermocoupleType}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Time Configuration</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>{this.props.connectedProbeReader.timeConfiguration}</Typography>
          </Grid>
        </Grid>
      </Container>
    ) : (
      <Redirect to="/" />
    );
  }
}

Device.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
  connect(
    mapToProps,
    actions
  )(Device)
);
