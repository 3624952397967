import React from "react";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: "2rem"
  },
  cardHeader: {
    padding: "16px"
  },
  cardBody: {
    padding: "16px"
  }
});

class NotFound extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Container maxWidth="xl" className={classes.root}>
        <Card>
          <div className={classes.cardHeader}>
            <h2>404! Page not found.</h2>
          </div>
          <div className={classes.cardBody}>
            Looks like the page you are trying to access, doesn't exist.
          </div>
        </Card>
      </Container>
    );
  }
}

export default withStyles(styles)(NotFound);
